/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getIvsEvents = /* GraphQL */ `
  query GetIvsEvents($eventid: String!) {
    getIvsEvents(eventid: $eventid) {
      eventid
      name
      datum
      description
      key
      interfacetype
      interfaceconfig
      streamurl
      slots
      slothash
      __typename
    }
  }
`;
export const listIvsEvents = /* GraphQL */ `
  query ListIvsEvents(
    $filter: TableIvsEventsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIvsEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        eventid
        name
        datum
        description
        key
        interfacetype
        interfaceconfig
        streamurl
        slots
        slothash
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getIvsParticipants = /* GraphQL */ `
  query GetIvsParticipants($eventid: String!, $userid: String!) {
    getIvsParticipants(eventid: $eventid, userid: $userid) {
      eventid
      userid
      azureidentity
      userinfo
      timestamp
      useragent
      done
      failed
      __typename
    }
  }
`;
export const listIvsParticipants = /* GraphQL */ `
  query ListIvsParticipants(
    $filter: TableIvsParticipantsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIvsParticipants(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        eventid
        userid
        azureidentity
        userinfo
        timestamp
        useragent
        done
        failed
        __typename
      }
      nextToken
      __typename
    }
  }
`;
