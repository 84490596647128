<template>
    <div class="view-events" style="z-index: 999; position: fixed; left: 0; right: 0; top: 0; bottom: 0; background: #000000; color: #ffffff;">
      <loading-wheel v-if="loading"></loading-wheel>

      <div v-else-if="event.slots" style="width: 100%; height: 100%; display: flex; flex-direction: column; align-items: stretch; align-content: stretch;">
        <div v-for="(slot, index) in event.slots" :key="index" style="flex-grow: 1; border-bottom: 1px solid #333333; text-align: center; display: flex; flex-wrap: nowrap; justify-content: space-around; align-items: center; flex-direction:row; align-content: center; font-size:10vh; line-height:10vh; font-family: 'Barlow Condensed', sans-serif;">
          <div style="padding-left: 1em; height: 11vh;">
            <i class="circle icon" :class="{ 'red': (slot.state !== 'ready' && slot.state !== 'onboarding'), 'orange': (slot.state === 'onboarding'), 'green': (slot.state === 'ready') }"></i>
          </div>
          <div style="flex-grow: 1; height: 11vh; overflow:hidden; text-align: center; width:100%;">{{ slot.participant && participant(slot.participant) && participant(slot.participant).userinfo ? JSON.parse(participant(slot.participant).userinfo).name : '-' }}</div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import moment from 'moment'
  import { Logger } from 'aws-amplify'
  import { API, graphqlOperation } from 'aws-amplify'
  
  import { getIvsEvents, listIvsParticipants } from '../graphql/queries'
  import { onUpdateIvsEvents, onCreateIvsParticipants, onUpdateIvsParticipants } from '../graphql/subscriptions'
  
  import LoadingWheel from '../components/LoadingWheel'

  moment.locale('de')

  const logger = new Logger('Bauchbinde.vue')


  export default {
    data() {
      return {
        event: {},
        participants: [],
        loading: true,
        subscriptions: [],
      }
    },

    methods: {
      participant(pid) {
        return this.participants.find(({ userid }) => userid === pid);
      },

      loadList(query, filter, value, nextToken = null) {
        API.graphql(graphqlOperation(query, { filter: filter, nextToken: nextToken }))
        .then(res => {
          value.push(...res.data.listIvsParticipants.items)
          
          if(res.nextToken) {
            this.loadList(query, filter, value, res.nextToken)
          }
        })
        .catch(err => {
          logger.debug('graphql error', err)
          value = []
        })
      },

      loadData() {
        this.loading = true

        this.participants = []
        this.loadList(listIvsParticipants, {
          eventid: {
            eq: this.$route.params.id
          }
        }, this.participants)

        API.graphql(graphqlOperation(getIvsEvents,  { 
          eventid: this.$route.params.id 
        }))
        .then(res => {
          if(res.data && res.data.getIvsEvents) {
            this.event = this.parseData(res.data.getIvsEvents)
          }
          this.modified = false
          this.loading = false
        })
        .catch(err => logger.debug('graphql error', err))
      },

      parseData(input) {
        let data = { ...input }
        if(data.interfaceconfig)
          data.interfaceconfig = JSON.parse(data.interfaceconfig)
        if(data.slots)
          data.slots = JSON.parse(data.slots)
        return data
      },

      stringifyData(input) {
        let data = { ...input }
        if(data.interfaceconfig)
          data.interfaceconfig = JSON.stringify(data.interfaceconfig)
        if(data.slots)
          data.slots = JSON.stringify(data.slots)
        return data
      }
    },

    created() {
      this.loadData()
      
      var self = this;
      // Subscribe to change of event
      this.subscriptions.push = API.graphql(
        graphqlOperation(onUpdateIvsEvents, { input: {
            eventid: this.event.eventid
          }
        })
      ).subscribe({
        next: (action) => {
          logger.debug("SUB onUpdateIvsEvents", action)
          self.event.slots = JSON.parse(action.value.data.onUpdateIvsEvents.slots)
        },
          error: (error) => logger.debug(error)
      })

      this.subscriptions.push = API.graphql(
        graphqlOperation(onCreateIvsParticipants, { input: {
            eventid: this.event.eventid
          }
        })
      ).subscribe({
        next: (action) => {
          logger.debug("SUB onCreateIvsParticipants", action)
          self.participants.push(action.value.data.onCreateIvsParticipants)
        },
          error: (error) => logger.debug(error)
      })

      this.subscriptions.push = API.graphql(
        graphqlOperation(onUpdateIvsParticipants, { input: {
            eventid: this.event.eventid
          }
        })
      ).subscribe({
        next: (action) => {
          logger.debug("SUB onUpdateIvsParticipants", action)
          for(let i = 0 ; i < self.participants.length ; i++) {
            if(self.participants[i].userid === action.value.data.onUpdateIvsParticipants.userid) {
              self.$set(self.participants, i, action.value.data.onUpdateIvsParticipants)
              break
            }
          }
        },
          error: (error) => logger.debug(error)
      })
            
      logger.debug("added " + this.subscriptions.length + " subscriptions")
    },

    beforeUnmount() {
      for(let i = 0 ; i < this.subscriptions.length ; i++) {
        this.subscriptions[i].unsubscribe()
        logger.debug("unsubscribed " + i)
      }
    },
  
    components: {
        LoadingWheel
    },
  }
  </script>
  
  <style scoped>
    @import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@500&display=swap');
  </style>
