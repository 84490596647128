import Vue from 'vue'
import Router from 'vue-router'

import Events from '../views/Events'
import Event from '../views/Event'
import Bauchbinden from '../views/Bauchbinden'

import { AuthRouter, AuthFilter } from '../amplify'

Vue.use(Router)

const router =  new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'events',
      component: Events,
      meta: {
        breadcrumb: [
          { name: 'Veranstaltungen' }
        ]
      }
    },
    {
      path: '/event/:id',
      name: 'event',
      component: Event,
      meta: {
        breadcrumb: [
          { name: 'Veranstaltungen', link: '/' },
          { name: 'Veranstaltung' }
        ]
      }
    },
    {
      path: '/bauchbinden/:id',
      name: 'bauchbinden',
      component: Bauchbinden,
      meta: {
        breadcrumb: [
          { name: 'Bauchbinden', link: '/' },
          { name: 'Bachbinden' }
        ]
      }
    },
    AuthRouter
  ]
})

router.beforeEach(AuthFilter)

export default router
