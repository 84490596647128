<template>
  <div class="card" style="overflow: hidden;">
    <div class="content">
      <div v-if="s.state === 'lock' || s.state === 'failed' || s.state === 'done'" class="ui black right corner label">
          <i class="lock icon"></i>
      </div>
      <div v-if="s.state === 'ready'" class="ui green right corner label">
          <i class="check icon"></i>
      </div>
      <div v-if="(!s.state || s.state ==='open') && s.participant" class="ui blue right corner label">
          <i class="info icon"></i>
      </div>
      <div v-if="(s.state ==='onboarding') && s.participant" class="ui yellow right corner label">
          <i class="search icon"></i>
      </div>
      <div class="header">
        <h1>
        {{ k }}
        </h1>
      </div>
      <div class="meta">
        {{ s.state || '-' }}
      </div>
      <div class="description">
        <div class="ui list">
            <div class="item">
                <i class="user icon"></i>
                <div class="content">
                  <b>{{ userinfo.name || '' }}</b>                           
                </div>
            </div>
            <div class="item">
                <i class="id card icon"></i>
                <div class="content">
                {{ s.participant !== 'locked' ? s.participant : '' }}
                </div>
            </div>
            <div class="item">
                <i class="mail icon"></i>
                <div class="content">
                  {{ userinfo.mail || '' }}
                </div>
            </div>
            <div class="item">
                <i class="phone icon"></i>
                <div class="content">
                  {{ userinfo.phone || '' }}
                </div>
            </div>
            <div class="item">
              <i class="clock icon"></i>
              <div class="content">
                {{ timestamp }}
              </div>
            </div>
            <div class="item">
              <span class="ui basic label" v-if="hasUserAgent">
                <i v-if="os.name === 'Windows'" class="blue windows icon"></i>
                <i v-else-if="os.name === 'Mac OS'" class="apple icon"></i>
                <i v-else-if="os.name === 'Android'" class="green android icon"></i>
                <i v-else>{{ os.name }} </i>{{ osversion }}
              </span>
              <span class="ui basic label" v-if="hasUserAgent">
                <i v-if="browser.name === 'Chrome'" class="chrome icon"></i>
                <i v-else-if="browser.name.includes('Safari')" class="blue safari icon"></i>
                <i v-else-if="browser.name === 'Edge'" class="blue edge icon"></i>
                <i v-else-if="browser.name === 'Firefox'" class="red firefox icon"></i>
                <i v-else>{{ browser.name }} </i>{{ browserversion }}
              </span>
            </div>
        </div>

        <a class="ui violet label" :href="s.meeting" target="_blank">
            <i class="phone icon"></i>
            Teams Meeting
        </a>
      </div>
    </div>
    <div class="extra content">
      <div v-if="s.participant === ''" class="ui basic fluid button" v-on:click="changeState(k, s.participant, 'lock')">Raum sperren</div>
      <div v-if="s.participant !== '' && s.participant !== 'locked' && s.state !== 'ready' && s.state !== 'onboarding'" class="ui two buttons">
        <div class="ui red button" v-on:click="changeState(k, s.participant, 'failed')">entfernen</div>
        <div class="ui yellow button" v-on:click="changeState(k, s.participant, 'onboarding')">onboarding</div>
      </div>
      <div v-if="s.state === 'onboarding'" class="ui two buttons">
        <div class="ui red button" v-on:click="changeState(k, s.participant, 'failed')">fehlgeschlagen</div>
        <div class="ui green button" v-on:click="changeState(k, s.participant, 'ready')">bereit</div>
      </div>
      <div v-if="s.state === 'ready'" class="ui two buttons">
        <div class="ui yellow button" v-on:click="changeState(k, s.participant, 'onboarding')">onboarding</div>
        <div class="ui basic red button" v-on:click="changeState(k, s.participant, 'done')">beenden</div>
      </div>
      <div v-if="s.participant === 'locked'" class="ui basic fluid button" v-on:click="changeState(k, s.participant, 'open')">Raum freigeben</div>
    </div>
  </div>
</template>

<script>  
    import moment from 'moment'
    import { UAParser } from 'ua-parser-js'

    moment.locale('de')
    
    export default {
        name: 'EventSlot',
        props: {
            s: Object,
            participants: Array,
            k: String
        },

        computed: {
          participant() {
            return this.participants.find(({ userid }) => userid === this.s.participant);
          },

          userinfo() {
            if(this.participant && this.participant.userinfo)
              return JSON.parse(this.participant.userinfo)
            return {}
          },

          azureid() {
            if(this.participant && this.participant.azureidentity)
              return JSON.parse(this.participant.azureidentity)
            return {}
          },

          timestamp() {
              if(this.participant && this.participant.timestamp)
                  return moment(this.participant.timestamp).format('DD.MM.YYYY HH:mm:ss.SSS')
              return ''
          },

          browser() {
            if(this.participant && this.participant.useragent)
              return new UAParser(this.participant.useragent).getBrowser();
            return ''
          },

          browserversion() {
            if(this.participant && this.participant.useragent)
              return new UAParser(this.participant.useragent).getBrowser().version;
            return ''
          },

          hasUserAgent() {
            return (this.participant && this.participant.useragent)
          },

          os() {
            if(this.participant && this.participant.useragent)
              return new UAParser(this.participant.useragent).getOS();
            return ''
          },

          osversion() {
            if(this.participant && this.participant.useragent)
              return new UAParser(this.participant.useragent).getOS().version;
            return ''
          }
        },

        methods: {
            changeState(key, userid, state) {
              this.$emit('change-state', key, userid, state)
            }
        }
    }
</script>

<style>

</style>
