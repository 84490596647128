<template>
    <div class="ui warning message">
      <div class="header">
        Keine {{name}} gefunden.
      </div>
      Bitte einen Eintrag anlegen.
    </div>
</template>

<script>
    export default {
        name: 'NoDataFound',
        props: {
            name: String
        }
    }
</script>