<template>
    <div class="ui dimmer modals page transition visible active" style="display: flex !important;">
        <div class="ui active modal">
            <div class="header">
                Neue Veranstaltung anlegen
            </div>
            <div class="image content">
                <div class="ui medium image" style="display: flex; justify-content: center; align-items: center;">
                    <i v-if="page == 0" class="exclamation circle icon" />
                    <i v-if="page == 1" class="cog icon" />
                    <i v-if="page == 2" class="exchange icon" />
                </div>
                <div class="description" style="flex: 1 1 auto;">
                    <form class="ui form" v-if="page == 0">
                        <h4 class="ui dividing header">Allgemeine Informationen</h4>
                        
                        <div class="field">
                            <label>EventID</label>
                            {{ eventid }}
                        </div>
                        <div class="field">
                            <label>Name</label>
                            <input type="text" v-model="name" placeholder="Veranstaltungsname">
                        </div>
                        <div class="field">
                            <label>Datum</label>
                            <input type="text" v-model.lazy="date" placeholder="Datum">
                        </div>
                        <div class="field">
                            <label>Beschreibung</label>
                            <textarea v-model="description"></textarea>
                        </div>
                    </form>

                    <form class="ui form" v-if="page == 1">
                        <h4 class="ui dividing header">Einstellungen</h4>

                        <div class="field">
                            <label>EventID</label>
                            {{ eventid }}
                        </div>
                        <div class="field">
                            <label>Anzahl virtueller Rednerpulte (Teams Meetings)</label>
                            <input type="number" v-model="slotcount" min="1" max="10">
                        </div>
                        <div class="field">
                            <label>Schnittstelle HV Portal</label>
                            <select class="ui fluid dropdown" v-model="interfacetype">
                                <option value="acs">ACS default</option>
                                <option value="computershare">Computershare</option>
                            </select>
                        </div>
                        <div class="field">
                            <label>Stream ARN</label>
                            <input type="text" v-model="streamurl" placeholder="arn:aws:ivs:...">
                        </div>
                    </form>

                    <form class="ui form" v-if="page == 2">
                        <h4 class="ui dividing header">Schnittstelle allgemein</h4>

                        <div class="field">
                            <label>EventID</label>
                            {{ eventid }}
                        </div>
                        <div class="field">
                            <label>Shared Secret für Authentifikation</label>
                            <div class="ui action input">
                                <input type="text" v-model="key" placeholder="Geheimer Schlüssel">
                                <button class="ui icon button" v-on:click.prevent="reCreateKey()">
                                    <i class="redo icon"></i>
                                </button>
                            </div>
                        </div>


                        <h4 class="ui dividing header">Schnittstelle {{ interfacetype }}</h4>
                        <div v-if="interfacetype == 'acs'">-</div>

                        <div v-if="interfacetype == 'computershare'">-</div>

                    </form>
                </div>
            </div>
            <div class="actions">
                <div class="ui deny button" style="float:left;" v-on:click.prevent="close()">
                    abbrechen
                </div>
                <div class="ui primary left labeled icon button" v-if="page > 0" v-on:click.prevent="back()">
                    zurück
                    <i class="arrow left icon"></i>
                </div><div class="ui primary right labeled icon button" v-if="page < 2" v-on:click.prevent="forward()">
                    weiter
                    <i class="arrow right icon"></i>
                </div>
                <div class="ui positive right labeled icon button" v-if="page >= 2" v-on:click.prevent="save()">
                    anlegen
                    <i class="checkmark icon"></i>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'

moment.locale('de')

export default {
    data() {
        return {
            eventid: uuidv4(),
            name: "Neue Veranstaltung",
            datum: new Date(),
            description: "",
            key: this.createKey(),
            interfacetype: "acs",
            interfaceconfig: {},
            streamurl: "",
            slotcount: 0,
            page: 0
        }
    },

    computed: {
        date: {
            get: function() {
                return moment(this.datum).format('DD.MM.YYYY')
            },
            set: function(newValue) {
                if (moment(newValue, 'DD.MM.YYYY', true).isValid())
                    this.datum = moment(newValue, 'DD.MM.YYYY').toDate()
                if (moment(newValue, 'DD.MM.YY', true).isValid())
                    this.datum = moment(newValue, 'DD.MM.YY').toDate()
                if (moment(newValue, 'DDMMYYYY', true).isValid())
                    this.datum = moment(newValue, 'DDMMYYYY').toDate()
                if (moment(newValue, 'DDMMYY', true).isValid())
                    this.datum = moment(newValue, 'DDMMYY').toDate()
                this.datum = moment(newValue, 'D.M.YYYY').toDate()
            }
        },
    },

    methods: {
        createKey() {
            const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
            const string_length = 32
            let randomstring = ''
            
            for (var i=0; i<string_length; i++) {
                var rnum = Math.floor(Math.random() * chars.length);
                randomstring += chars.substring(rnum,rnum+1);
            }
            return randomstring
        },

        reCreateKey() {
            this.key = this.createKey()
        },

        createComputershareID() {
            return 'csauth' + Math.floor(Math.random() * 32766);
        },

        close() {
            this.$emit('close')
        },

        back() {
            if(this.page > 0)
                this.page = this.page - 1
        },

        forward() {
            const regexUuid = /^[a-z,0-9,-]{36,36}$/;

            console.log(regexUuid.test(this.eventid))

            // check for auth type after setting auth type for id change with computershare auth (INT16)
            if(this.page == 1) {
                if(this.interfacetype == 'computershare' && regexUuid.test(this.eventid)) {
                    this.eventid = this.createComputershareID()
                }

                if(this.interfacetype != 'computershare' && !regexUuid.test(this.eventid)) {
                    this.eventid = uuidv4()
                }
            }

            this.page = this.page + 1
        },

        dataout() {
            const slottemplate = {
                meeting: '',
                participant: ''
            };

            let slots = {}

            for(let i = 0 ; i < this.slotcount ; i++) {
                slots[i] = slottemplate
            }

            let out = {
                eventid: this.eventid,
                name: this.name,
                datum: moment(this.datum).format('YYYY-MM-DD[Z]'),
                description: this.description,
                key: this.key,
                interfacetype: this.interfacetype,
                interfaceconfig: JSON.stringify(this.interfaceconfig),
                streamurl: this.streamurl,
                slots: JSON.stringify(slots)
            }

            return out
        },

        save() {
            this.$emit('save', this.dataout())
        },
    }
}
</script>
