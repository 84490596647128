<template>
    <div>
        <div class="ui cards" v-if="event && event.slots && typeof event.slots === 'object'">
            <EventSlot v-for="(slot, index) in event.slots" :participants="participants" :s="slot" :k="index" :key="index" @change-state="changeState"></EventSlot>
        </div>
    </div>
</template>

<script>  
    import moment from 'moment'
    import { Logger } from 'aws-amplify'
    import { API, graphqlOperation } from 'aws-amplify'

    import { updateIvsEvents, updateIvsParticipants } from '../graphql/mutations'

    import EventSlot from './EventSlot'

    moment.locale('de')
    const logger = new Logger('EventSlots.vue')
    
    export default {
        name: 'EventSlots',
        props: {
            event: Object,
            participants: Array
        },

        data() {
            return {
           }
        },

        computed: {
        },

        methods: {
            changeState(key, userid, state) {
                let newSlots = { ...this.event.slots };

                newSlots[key].state = state

                switch (state) {
                    case 'lock':
                        newSlots[key].participant = 'locked'
                        break;
                    case 'open':
                        newSlots[key].participant = ''
                        break;
                    case 'ready':
                        break;
                    case 'failed':
                    case 'done':
                        newSlots[key].participant = 'locked'
                        break;
                }

                API.graphql(graphqlOperation(updateIvsEvents, {
                            input: {
                                eventid: this.event.eventid,
                                slots: JSON.stringify(newSlots)
                            }
                        })).then(res => {
                            if(res.data) {
                                logger.debug('graphql update', res)
                            }
                        }).catch(err => logger.debug('graphql update error', err))

                if(state === 'failed') {
                    API.graphql(graphqlOperation(updateIvsParticipants, {
                            input: {
                                eventid: this.event.eventid,
                                userid: userid,
                                failed: true
                            }
                        })).then(res => {
                            if(res.data) {
                                logger.debug('graphql update', res)
                            }
                        }).catch(err => logger.debug('graphql update error', err))
                }

                if(state === 'done') {
                    API.graphql(graphqlOperation(updateIvsParticipants, {
                            input: {
                                eventid: this.event.eventid,
                                userid: userid,
                                done: true
                            }
                        })).then(res => {
                            if(res.data) {
                                logger.debug('graphql update', res)
                            }
                        }).catch(err => logger.debug('graphql update error', err))
                }
            }
        },

        components: {
            EventSlot
        }
    }
</script>

<style>

</style>
