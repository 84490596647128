import Auth from '@aws-amplify/auth'

var config = {
    Auth: {
      identityPoolId: 'eu-central-1:a3799814-cb01-4f6f-8687-f06b6e507851',
      region: 'eu-central-1',
      userPoolId: 'eu-central-1_M8OUVZgUr',
      userPoolWebClientId: '4dcvidc13uhu97lp5kckv40fsp',
      authenticationFlowType: 'USER_PASSWORD_AUTH'
    },
    API: {
      endpoints: [
          {
              name: "IVS Admin Backend API",
              endpoint: "https://fa44bpex58.execute-api.eu-central-1.amazonaws.com/prod",
              custom_header: async () => {
                return { Authorization: (await Auth.currentSession()).idToken.jwtToken }
              }
          }
      ]
    },

    'aws_appsync_graphqlEndpoint': 'https://gporspdckrbelamuklq2ncn6w4.appsync-api.eu-central-1.amazonaws.com/graphql',
    'aws_appsync_region': 'eu-central-1',
    'aws_appsync_authenticationType': 'AMAZON_COGNITO_USER_POOLS',
}

export default config;
