<template>
    <div class="view-events">
      <loading-wheel v-if="loading"></loading-wheel>

      <div v-else>
<!--
        <button v-if="!loading" v-on:click.prevent="" class="ui right labeled icon button right floated">
          <i class="edit icon"></i>
          bearbeiten
        </button>
-->
        <button v-if="!loading" v-on:click.prevent="" class="ui right labeled black icon button right floated" @click="$router.push('../bauchbinden/' + $route.params.id)">
          <i class="closed captioning icon"></i>
          Bauchbinden
        </button>


        <h2 class="ui header">
          <i class="users icon"></i>
          <div class="content">
            {{ event.name }}
            <div class="sub header">{{ $route.params.id }}</div>
          </div>
        </h2>

        <div class="ui top attached tabular menu">
          <div class="item" :class="{ active: (tab == 0) }" v-on:click="(tab = 0)">Management</div>
          <div class="item" :class="{ active: (tab == 1) }" v-on:click="(tab = 1)">Einstellungen</div>
        </div>
        
        <div class="ui bottom attached tab segment" :class="{ active: (tab == 0) }">

          <div style="display: flex;">
            <div style="flex-grow: 1;">
              <EventSlots :event="event" :participants="participants"></EventSlots>
            </div>
            <div style="flex-grow: 0;">
              <ParticipantList :event="event" :participants="participants"></ParticipantList>
            </div>
          </div>

        </div>

        <div class="ui bottom attached tab segment" :class="{ active: (tab == 1) }">
          <div class="ui grid" style="margin-top:2em;">
            <div class="eight wide column">
              <table class="ui very basic celled table">
                <tbody>
                  <tr>
                    <td>
                      Event ID
                    </td>
                    <td>
                      {{ event.eventid }}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Name
                    </td>
                    <td>
                      <div class="ui fluid transparent input">
                        <input type="text" v-model="event.name">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Datum
                    </td>
                    <td>
                      <div class="ui fluid transparent input">
                        <input type="text" v-model="timestamp">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Beschreibung
                    </td>
                    <td>
                      <div class="ui fluid transparent input">
                        <div class="ui form" style="width:100%;">
                          <div class="field">
                            <textarea v-model="event.description"></textarea>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Schlüssel
                    </td>
                    <td>
                      <div class="ui fluid transparent input">
                        <input type="text" v-model="event.key">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Livestream ARN
                    </td>
                    <td>
                      <div class="ui fluid transparent input">
                        <input type="text" v-model="event.streamurl">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Schnittstelle
                    </td>
                    <td>
                      <select class="ui fluid dropdown" v-model="event.interfacetype" style="background:transparent; border:none; -webkit-appearance: none;">
                        <option value="acs">ACS</option>
                        <option value="computershare">Computershare</option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Schnittstelle Config
                    </td>
                    <td>
                      {{ event.interfaceconfig }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="eight wide column">
              <table class="ui very basic celled table">
                <tbody>
                  <tr>
                    <td>
                      Testlink
                    </td>
                    <td>
                      <a :href="testLink()" target="_blank">Computershare Testlink</a>
                    </td>
                  </tr>
                </tbody>
              </table>
              <h3>Slots</h3>
              <table class="ui very basic celled table">
                <tbody>
                  <tr v-for="(slot, key) in event.slots" v-bind:key="key">
                    <td style="text-align: center;">
                      <h3>{{ key }}</h3>
                    </td>
                    <td>
                      <div class="ui label">
                        <i class="phone icon"></i> meeting
                      </div>
                      
                      <div class="ui label">
                        <i class="user icon"></i> {{ slot.participant }}
                      </div>
                      <div class="ui label">
                        <i class="icon" :class="{ 'lock': (slot.state == 'lock' || slot.state == 'failed' || slot.state == 'done'), 'square outline': (slot.state == 'open'), 'check': (slot.state == 'ready'), 'search': (slot.state == 'onboarding') }"></i> {{ slot.state }}
                      </div>
                    </td>
                    <td style="text-align: right; border-left:none;">
                      <button class="ui icon red button" v-on:click="deleteSlot(key)">
                        <i class="minus icon"></i>
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="3" style="text-align: center;">
                      <button class="ui icon green button" v-on:click="addSlot()">
                        <i class="plus icon"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="ui grid" style="margin-top:2em;">
            <div class="sixteen wide column">
              <h2>Teilnehmer</h2>
              <table class="ui celled table">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="participant in participants" :key="participant.userid">
                    <td>{{ participant.userid }}</td>
                    <td>{{ JSON.parse(participant.userinfo).name }}</td>
                    <td style="text-align: right;">
                      <button class="ui icon red button" v-on:click="deleteParticipant(participant.userid)">
                            <i class="minus icon"></i>
                          </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

      </div>
    </div>
  </template>
  
  <script>
  import moment from 'moment'
  import { Logger } from 'aws-amplify'
  import { API, graphqlOperation } from 'aws-amplify'
  import sign from 'jwt-encode'
  
  import { getIvsEvents, listIvsParticipants } from '../graphql/queries'
  import { deleteIvsParticipants } from '@/graphql/mutations'
  import { onUpdateIvsEvents, onCreateIvsParticipants, onUpdateIvsParticipants, onDeleteIvsParticipants } from '../graphql/subscriptions'
  
  import LoadingWheel from '../components/LoadingWheel'
  import EventSlots from '../components/EventSlots'
  import ParticipantList from '../components/ParticipantList'

  moment.locale('de')

  const logger = new Logger('Event.vue')

  const testNutzerNamen = [
    "Max Mustermann",
    "Anna Musterfrau",
    "Peter Tester",
    "Lisa Beispiel",
    "Tom Probierer",
    "Julia Test",
    "Tim Testmann",
    "Sarah Prüfer",
    "Markus Muster",
    "Laura Test",
    "David Demo",
    "Nina Test",
    "Alexandra Check",
    "Christian Proband",
    "Johanna Prüfung",
    "Sebastian Probe",
    "Isabel Inspektion",
    "Felix Praxis",
    "Carina Checkliste",
    "Dominik Dummy",
    "Verena Versuch",
    "Janina Testlauf",
    "Daniel Durchgang",
    "Leonie Leistung",
    "Simon Simulation",
    "Sophie System",
    "Andreas Analyse",
    "Elena Experiment",
    "Marc Musterung"
  ];

  export default {
    data() {
      return {
        event: {},
        participants: [],
        test: {},
        loading: true,
        modified: false,
        subscriptions: [],
        tab: 0
      }
    },

    computed: {
      timestamp() {
        if(this.event && this.event.datum)
          return moment(this.event.datum, 'YYYY-MM-DDZ').format('DD.MM.YYYY')
        return ''
      },
    },

    methods: {
      testLink() {
        if(this.event.interfacetype == "computershare") {
          let csid = this.$route.params.id.match(/\d+/g)
          let uri = "https://ivs.streaming-solution.de/csauth/" + csid + "/?jwt="
          let token = sign(
          {
            "event_id": csid,
            "sub": "TESTUSER-" + Math.floor(Date.now() / 1000),
            "exp": Math.floor(Date.now() / 1000) + 3600,
            "permissions": {
              "so": 128
            },
            "userinfo": {
              "name": testNutzerNamen[Math.floor(Math.random() * 29)],
              "mail": "testuser@streaming-solution.de",
              "phone": "+49 12345 6789 - 0"
            }
          }, this.event.key);
          return uri + token;
        }
      },

      addSlot() {
        
      },

      deleteSlot() {

      },

      deleteParticipant(userid) {
        console.log("delete participant", this.$route.params.id, userid)

        API.graphql(graphqlOperation(deleteIvsParticipants, {
                    input: {
                      eventid: this.$route.params.id,
                      userid: userid
                    }
                })).then(res => {
                    logger.debug('graphql event deleted', res)
                })
                .catch(err => { 
                    logger.debug('graphql error', err) 
                })
      },

      loadList(query, filter, value, nextToken = null) {
        API.graphql(graphqlOperation(query, { filter: filter, nextToken: nextToken }))
        .then(res => {
          value.push(...res.data.listIvsParticipants.items)
          
          if(res.nextToken) {
            this.loadList(query, filter, value, res.nextToken)
          }
        })
        .catch(err => {
          logger.debug('graphql error', err)
          value = []
        })
      },

      loadData() {
        this.loading = true

        this.participants = []
        this.loadList(listIvsParticipants, {
          eventid: {
            eq: this.$route.params.id
          }
        }, this.participants)

        API.graphql(graphqlOperation(getIvsEvents,  { 
          eventid: this.$route.params.id 
        }))
        .then(res => {
          if(res.data && res.data.getIvsEvents) {
            this.event = this.parseData(res.data.getIvsEvents)
          }
          this.modified = false
          this.loading = false
        })
        .catch(err => logger.debug('graphql error', err))
      },

      parseData(input) {
        let data = { ...input }
        if(data.interfaceconfig)
          data.interfaceconfig = JSON.parse(data.interfaceconfig)
        if(data.slots)
          data.slots = JSON.parse(data.slots)
        return data
      },

      stringifyData(input) {
        let data = { ...input }
        if(data.interfaceconfig)
          data.interfaceconfig = JSON.stringify(data.interfaceconfig)
        if(data.slots)
          data.slots = JSON.stringify(data.slots)
        return data
      }
    },

    created() {
      this.loadData()
      
      var self = this;
      // Subscribe to change of event
      this.subscriptions.push = API.graphql(
        graphqlOperation(onUpdateIvsEvents, { input: {
            eventid: this.event.eventid
          }
        })
      ).subscribe({
        next: (action) => {
          logger.debug("SUB onUpdateIvsEvents", action)
          self.event.slots = JSON.parse(action.value.data.onUpdateIvsEvents.slots)
        },
          error: (error) => logger.debug(error)
      })

      this.subscriptions.push = API.graphql(
        graphqlOperation(onCreateIvsParticipants, { input: {
            eventid: this.event.eventid
          }
        })
      ).subscribe({
        next: (action) => {
          logger.debug("SUB onCreateIvsParticipants", action)
          self.participants.push(action.value.data.onCreateIvsParticipants)
        },
          error: (error) => logger.debug(error)
      })

      this.subscriptions.push = API.graphql(
        graphqlOperation(onUpdateIvsParticipants, { input: {
            eventid: this.event.eventid
          }
        })
      ).subscribe({
        next: (action) => {
          logger.debug("SUB onUpdateIvsParticipants", action)
          for(let i = 0 ; i < self.participants.length ; i++) {
            if(self.participants[i].userid === action.value.data.onUpdateIvsParticipants.userid) {
              self.$set(self.participants, i, action.value.data.onUpdateIvsParticipants)
              break
            }
          }
        },
          error: (error) => logger.debug(error)
      })

      this.subscriptions.push = API.graphql(
        graphqlOperation(onDeleteIvsParticipants, { input: {
            eventid: this.event.eventid
          }
        })
      ).subscribe({
        next: (action) => {
          logger.debug("SUB onDeleteIvsParticipants", action)
          for(let i = 0 ; i < self.participants.length ; i++) {
            if(self.participants[i].userid === action.value.data.onDeleteIvsParticipants.userid) {
              self.$delete(self.participants, i)
              break
            }
          }
        },
          error: (error) => logger.debug(error)
      })
            
      logger.debug("added " + this.subscriptions.length + " subscriptions")
    },

    beforeUnmount() {
      for(let i = 0 ; i < this.subscriptions.length ; i++) {
        this.subscriptions[i].unsubscribe()
        logger.debug("unsubscribed " + i)
      }
    },
  
    components: {
        LoadingWheel,
        EventSlots,
        ParticipantList
    },
  }
  </script>
  
  <style>

  </style>
