<template>
    <div class="view-events">
      <button v-if="!loading" v-on:click.prevent="newEvent = true;" class="ui right labeled icon button right floated teal">
        <i class="add icon"></i>
        neue Veranstaltung
      </button>
  
  
      <h1>Veranstaltungen</h1>
      <LoadingWheel v-if="loading" />

      <div v-if="!loading && nodata">
          <NoDataFound name="Veranstaltungen" />
      </div>

      <div v-else>
        <ListEventObject v-for="event in events" :event="event" :key="event.eventid" />
      </div>
      
      
      <NewEvent v-if="newEvent" @save="create" @close="newEvent = false;" />
    </div>
  </template>
  
  <script>
  import moment from 'moment'

  moment.locale('de')

  import { Auth, Logger } from 'aws-amplify'
  import { API, graphqlOperation } from 'aws-amplify'
  import { listIvsEvents } from '../graphql/queries'
  import { createIvsEvents } from '../graphql/mutations'
  import { promiseWithTimeout } from '../promise-timeout'

  const logger = new Logger('Events.vue')

  import LoadingWheel from '../components/LoadingWheel'
  import NoDataFound from '../components/NoDataFound'
  import ListEventObject from '../components/ListEventObject'
  import NewEvent from '../components/NewEvent'
  
  export default {
    data() {
      return {
        events: [],
        loading: true,
        nodata: true,
        newEvent: false
      }
    },

    methods: {
        create(data) {
            this.newEvent = false

            console.log('data',data)

            API.graphql(graphqlOperation(createIvsEvents, {
                    input: data
                })).then(res => {
                    logger.debug('graphql event created', res)
                    this.events.push(res.data.createIVSEvents)
                })
                .catch(err => { 
                    logger.debug('graphql error', err) 
                })
        },

        loadData() {
            let doIt = promiseWithTimeout(15000, API.graphql(graphqlOperation(listIvsEvents)))  
        
            // Wait for the promise to get resolved
            doIt.then(res => {
                logger.debug('data', res)
                if(res.data && res.data.listIvsEvents) {
                    this.events = res.data.listIvsEvents.items
                    this.nodata = false
                }

                if( this.events.length === 0 )
                    this.nodata = true

                this.loading = false
            })

            // Wait for the promise to get rejected or timed out
            doIt.catch(err => {
                logger.debug('graphql error', err)
                this.loading = false
                this.nodata = true
            })
            }
    },

    mounted() {
        // refresh current Session
        Auth.currentSession()
        .then(data => logger.debug(data))
        .catch(err => logger.debug(err));
  
        logger.debug('mounted')

        this.loadData()
    },
  
    components: {
        LoadingWheel, NoDataFound, ListEventObject, NewEvent
    },
  }
  </script>
  
  <style>

  </style>
