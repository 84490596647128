<template>
    <div class="ui segment unstackable items" @click="$router.push('event/' + event.eventid)">
        <div class="item">
            <div class="image">
                <i class="huge users icon"></i>
            </div>
            <div class="content">
                <router-link :to="{'path': '/event/' + event.eventid}" class="header">{{ event.name }}</router-link>
                <div class="meta">{{ event.eventid }}</div>
                <div style="font-weight: bold;">{{ beautyDate(event.datum) }}</div>
                <div class="description">
                    <p>{{ event.description }}</p>
                </div><br />
                <div>
                    <div class="ui label">
                        <i class="exchange icon"></i>
                        {{ event.interfacetype }}
                    </div>
                    <div class="ui label">
                        <i class="phone square icon"></i>
                        {{ slotcount }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>  
    import moment from 'moment'

    moment.locale('de')
    
    export default {
        name: 'ListEventObject',
        props: {
            event: Object
        },

        computed: {
            slotcount() {
                let slots = JSON.parse(this.event.slots)
                return Object.getOwnPropertyNames(slots).length
            }
        },
        methods: {
            beautyDate(date) {
                let mdate = moment(date, 'YYYY-MM-DDZ')
                if(mdate > 0)
                    return mdate.format("dddd, Do MMMM YYYY")
                return "-"
            },
        }
    }
</script>

<style>
    .ui.items > .item > .image {
      background: #bcbec0;
      text-align: center;
      line-height: 9em;
      color: #e7e8e9;
    }
</style>
