import Vue from 'vue'
import 'semantic-ui-css/semantic.min.css'
import 'material-icons/iconfont/material-icons.css';

import { Amplify, Logger } from 'aws-amplify'
import aws_exports from './aws-exports'

Amplify.configure(aws_exports)
Logger.LOG_LEVEL = 'DEBUG'

Vue.config.productionTip = false

import App from './App.vue'

import router from './router'

new Vue({
  router: router,
  render: h => h(App),
}).$mount('#app')
