/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateIvsEvents = /* GraphQL */ `
  subscription OnCreateIvsEvents($eventid: String) {
    onCreateIvsEvents(eventid: $eventid) {
      eventid
      name
      datum
      description
      key
      interfacetype
      interfaceconfig
      streamurl
      slots
      slothash
      __typename
    }
  }
`;
export const onUpdateIvsEvents = /* GraphQL */ `
  subscription OnUpdateIvsEvents($eventid: String) {
    onUpdateIvsEvents(eventid: $eventid) {
      eventid
      name
      datum
      description
      key
      interfacetype
      interfaceconfig
      streamurl
      slots
      slothash
      __typename
    }
  }
`;
export const onDeleteIvsEvents = /* GraphQL */ `
  subscription OnDeleteIvsEvents($eventid: String) {
    onDeleteIvsEvents(eventid: $eventid) {
      eventid
      name
      datum
      description
      key
      interfacetype
      interfaceconfig
      streamurl
      slots
      slothash
      __typename
    }
  }
`;
export const onCreateIvsParticipants = /* GraphQL */ `
  subscription OnCreateIvsParticipants($eventid: String, $userid: String) {
    onCreateIvsParticipants(eventid: $eventid, userid: $userid) {
      eventid
      userid
      azureidentity
      userinfo
      timestamp
      useragent
      done
      failed
      __typename
    }
  }
`;
export const onUpdateIvsParticipants = /* GraphQL */ `
  subscription OnUpdateIvsParticipants($eventid: String, $userid: String) {
    onUpdateIvsParticipants(eventid: $eventid, userid: $userid) {
      eventid
      userid
      azureidentity
      userinfo
      timestamp
      useragent
      done
      failed
      __typename
    }
  }
`;
export const onDeleteIvsParticipants = /* GraphQL */ `
  subscription OnDeleteIvsParticipants($eventid: String, $userid: String) {
    onDeleteIvsParticipants(eventid: $eventid, userid: $userid) {
      eventid
      userid
      azureidentity
      userinfo
      timestamp
      useragent
      done
      failed
      __typename
    }
  }
`;
