<template>
    <div class="ui card">
    <div class="content">
        <i v-if="p.done" class="right floated green check large icon" style="margin-right: 0;"></i>
        <i v-else-if="p.failed" class="right floated red close large icon" style="margin-right: 0;"></i>
        <span v-else v-html="slotid"></span>
        <div class="header">{{ name }}</div>
        <div class="meta">{{ p.userid }}</div>
        <div class="description">
            <p><i class="id mail icon"></i> {{ mail }}</p>
            <p><i class="id phone icon"></i> {{ phone }}</p>
            <p><i class="key icon"></i>{{ azureid.communicationUserId || '' }}</p>
        </div>
    </div>
    <div class="extra content">
        <span class="left floated">
            <i class="clock icon"></i>{{ timestamp }}
        </span>
        <span class="right floated">
            <span v-if="p.useragent">
                <i v-if="os.name === 'Windows'" class="blue windows icon"></i>
                <i v-else-if="os.name === 'Mac OS'" class="apple icon"></i>
                <i v-else-if="os.name === 'Android'" class="green android icon"></i>
                <i v-else>{{ os.name }} </i>
            </span>
            <span v-if="p.useragent">
                <i v-if="browser.name === 'Chrome'" class="chrome icon"></i>
                <i v-else-if="browser.name.includes('Safari')" class="blue safari icon"></i>
                <i v-else-if="browser.name === 'Edge'" class="blue edge icon"></i>
                <i v-else-if="browser.name === 'Firefox'" class="red firefox icon"></i>
                <i v-else>{{ browser.name }} </i>
            </span>
        </span>
    </div>
    </div>
  </template>
  
  <script>  
      import moment from 'moment'
      import { UAParser } from 'ua-parser-js'
  
      moment.locale('de')
      
      export default {
          name: 'EventSlot',
          props: {
              p: Object,
              slots: Object
          },
  
          computed: {
            name() {
                return JSON.parse(this.p.userinfo).name
            },

            mail() {
                return JSON.parse(this.p.userinfo).mail
            },

            phone() {
                return JSON.parse(this.p.userinfo).phone
            },

            timestamp() {
                if(this.p.timestamp)
                    return moment(this.p.timestamp).format('DD.MM.YYYY HH:mm:ss.SSS')
                return ''
            },

            slotid() {
                const color = {
                    "ready": "green",
                    "onboarding": "orange",
                    "open": "blue"
                }

                for (let [key, value] of Object.entries(this.slots)) {
                    if(value.participant === this.p.userid)
                        return '<span class="right floated ui ' + color[value.state] + ' circular label">' + key + '</span>'
                }

                return ''
            },

            azureid() {
                if(this.p.azureidentity)
                    return JSON.parse(this.p.azureidentity)
                return {}
            },

            browser() {
                if(this.p.useragent)
                    return new UAParser(this.p.useragent).getBrowser();
                return null
            },

            os() {
                if(this.p.useragent)
                    return new UAParser(this.p.useragent).getOS();
                return null
            },
          },
  
          methods: {
          }
      }
  </script>
  
  <style>
  
  </style>