/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createIvsEvents = /* GraphQL */ `
  mutation CreateIvsEvents($input: CreateIvsEventsInput!) {
    createIvsEvents(input: $input) {
      eventid
      name
      datum
      description
      key
      interfacetype
      interfaceconfig
      streamurl
      slots
      slothash
      __typename
    }
  }
`;
export const updateIvsEvents = /* GraphQL */ `
  mutation UpdateIvsEvents($input: UpdateIvsEventsInput!) {
    updateIvsEvents(input: $input) {
      eventid
      name
      datum
      description
      key
      interfacetype
      interfaceconfig
      streamurl
      slots
      slothash
      __typename
    }
  }
`;
export const deleteIvsEvents = /* GraphQL */ `
  mutation DeleteIvsEvents($input: DeleteIvsEventsInput!) {
    deleteIvsEvents(input: $input) {
      eventid
      name
      datum
      description
      key
      interfacetype
      interfaceconfig
      streamurl
      slots
      slothash
      __typename
    }
  }
`;
export const createIvsParticipants = /* GraphQL */ `
  mutation CreateIvsParticipants($input: CreateIvsParticipantsInput!) {
    createIvsParticipants(input: $input) {
      eventid
      userid
      azureidentity
      userinfo
      timestamp
      useragent
      done
      failed
      __typename
    }
  }
`;
export const updateIvsParticipants = /* GraphQL */ `
  mutation UpdateIvsParticipants($input: UpdateIvsParticipantsInput!) {
    updateIvsParticipants(input: $input) {
      eventid
      userid
      azureidentity
      userinfo
      timestamp
      useragent
      done
      failed
      __typename
    }
  }
`;
export const deleteIvsParticipants = /* GraphQL */ `
  mutation DeleteIvsParticipants($input: DeleteIvsParticipantsInput!) {
    deleteIvsParticipants(input: $input) {
      eventid
      userid
      azureidentity
      userinfo
      timestamp
      useragent
      done
      failed
      __typename
    }
  }
`;
