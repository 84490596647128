<template>
    <div style="height: calc(100vh - 22em); overflow-y: scroll; padding: .1em 2em;">
        <ParticipantItem v-for="participant in reverseParticipants" :key="participant.userid" :p="participant" :slots="slots"></ParticipantItem>
    </div>
</template>

<script>
    import moment from 'moment'
    import { Logger } from 'aws-amplify'

    import ParticipantItem from '../components/ParticipantItem'



    moment.locale('de')
    const logger = new Logger('ParticipantList.vue')
    
    export default {
        name: 'ParticipantList',
        props: {
            participants: Array,
            event: Object
        },

        data() {
            return {
           }
        },

        computed: {
            reverseParticipants() {
                return this.participants.slice().reverse();
            },     
            slots() {
                if(this.event && this.event.slots)
                    return this.event.slots
                return {}
            }
        },

        methods: {
        },

        mounted() {
            logger.debug("mounted")
        },

        components: {
            ParticipantItem
        }
    }
</script>

<style>

</style>
