<template>
  <div id="app" class="ui fluid container" style="padding: 0 5em;">
    <!-- header menu -->
    <div v-if="hasUser" class="ui large fixed inverted menu">
      <div class="ui fluid container" style="padding: 0 4.5 em;">
        <router-link :to="{'path': '/'}" class="header item" style="width:19rem;">
          <img class="logo" src="./assets/acslogo.svg" style="width:6em!important;margin-right:1em;">
          ACS interactive.video.solution
        </router-link>

        <div class="right menu">
          <div class="ui simple dropdown item">
            <i class="user circle icon"></i>{{user}}<i class="dropdown icon"></i>
            <div class="menu">
              <div class="divider"></div>
              <a class="item" v-on:click="signOut" href="#"><i class="sign-out icon"></i>abmelden</a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- main content -->
    <main class="" style="margin-top:6em;" role="main">
      <router-view></router-view>
    </main>
  </div>
</template>

<script>
import { Auth, Logger } from 'aws-amplify'
import AmplifyStore from './amplify/AmplifyStore'

const logger = new Logger('App.vue')

export default {
  name: 'App',
  data () {
    return {
      breadcrumbList: [],
    }
  },

  computed: {
    hasUser () {
      return AmplifyStore.getters.hasUser
    },

    user () {
      if(AmplifyStore.getters.getUser && AmplifyStore.getters.getUser.username)
        return AmplifyStore.getters.getUser.username
      return null
    },
  },

  methods: {
      signOut: function() {
        this.showmenu = false;
        Auth.signOut()
            .then(() => {
                this.$router.push('/auth/signIn')
            })
            .catch(err => this.setError(err))
    },

    setError: function(err) {
      logger.debug('sign out error', err)
    },
    updateList () { this.breadcrumbList = this.$route.meta.breadcrumb },
  },

  watch: {
    // Breadcrumb navigation
    '$route' () { logger.debug('route updated'); this.updateList() }
  },

  mounted () {
    this.updateList()
  }
}
</script>

<style>

</style>
